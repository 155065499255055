<template>
  <DashboardLayout>
    <div class="row g-3 justify-content-evenly">
      <div class="col-md-4 card p-0 border-dark rounded-0">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <h5 class="card-header bg-dark text-light p-3 rounded-0">
            İşlem Formu
          </h5>
          <div class="card-body">
            <ExchangesSelect
              labelName="İşlem Yapılacak Hesap"
              inputName="exchange"
              :errors="errors"
              :getData="getById"
            ></ExchangesSelect>
            <div class="d-flex mt-3 justify-content-evenly">
              <div>USD: {{ wallet.USD }}$</div>
              <div>BTC: {{ wallet.BTC }}</div>
            </div>
            <RatioSelect
              inputName="ratio"
              :setRatio="setRatio"
              :errors="errors"
            />
          </div>
          <div class="card-footer">
            <div class="d-flex mt-2">
              <button
                type="submit"
                @click="buy"
                class="btn btn-success rounded-0 w-100 me-2"
              >
                AL
              </button>
              <button
                type="submit"
                @click="sell"
                class="btn btn-danger rounded-0 w-100 ms-2"
              >
                SAT
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-11 card p-0 border-dark rounded-0">
        <h5 class="card-header bg-dark text-light p-3 rounded-0">İşlemlerim</h5>
        <div class="card-body">İşlem</div>
      </div>
      <div></div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-11 card p-0 border-dark rounded-0">
        <h5 class="card-header bg-dark text-light p-3 rounded-0">
          İşlem Geçmişim
        </h5>
        <div class="card-body">İşlem</div>
      </div>
      <div></div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";
import ExchangesSelect from "@/components/ExchangesSelect.vue";
import RatioSelect from "@/components/RatioSelect.vue";

import { mapActions, mapState } from "vuex";

import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
    DashboardLayout,
    ExchangesSelect,
    Form,
    RatioSelect,
  },
  data() {
    return {
      exchange: "",
      api_key: "",
      secret_key: "",
      ratio: "",
    };
  },
  methods: {
    ...mapActions("exchange", [
      "getExchange",
      "connectExchange",
      "submitOrder",
    ]),
    getById(id) {
      const exchange = this.exchanges.filter((exchange) => {
        return exchange.id == id;
      });
      this.exchange = exchange[0].exchange;
      this.api_key = exchange[0].api_key;
      this.secret_key = exchange[0].secret_key;
      this.connectExchange({
        data: {
          exchange: this.exchange,
          api_key: this.api_key,
          secret_key: this.secret_key,
        },
      });
    },
    setRatio(e) {
      this.ratio = e.target.value;
    },
    onSubmit() {},
    buy() {
      let account = document.getElementById("exchange").value;
      if (account !== "" && this.ratio !== "") {
        this.submitOrder({
          data: {
            api_key: this.api_key,
            secret_key: this.secret_key,
            exchange_name: this.exchange,
            exchange_type: "BUY",
            balance_ratio: parseInt(this.ratio),
            symbol: "BTCUSD",
            type: "LIMIT",
          },
        });
      }
    },
    sell() {
      let account = document.getElementById("exchange").value;
      if (account !== "" && this.ratio !== "") {
        this.submitOrder({
          data: {
            api_key: this.api_key,
            secret_key: this.secret_key,
            exchange_name: this.exchange,
            exchange_type: "SELL",
            balance_ratio: parseInt(this.ratio),
            symbol: "BTCUSD",
            type: "LIMIT",
          },
        });
      }
    },
  },
  computed: {
    ...mapState("exchange", ["exchanges", "wallet"]),
  },
  setup() {
    const schema = Yup.object().shape({
      exchange: Yup.string().required("Zorunlu alan!"),
      ratio: Yup.string().required("Bir oran seçmelisiniz!"),
    });

    return {
      schema,
    };
  },
};
</script>

<style></style>
